import React from 'react';

import '../../base-styles/base.scss';
import './about.scss';

class About extends React.Component {
  render() {
    return (
      <>
        <section id="about">
          <img src={require('./profilePhoto.jpg')} alt="Hubert profile pic" />
          <div id="about-content">
            <p>
              I'm Hubert. I am a software engineer at FreeWheel. My day-to-day
              involves React.js and Golang. I am originally from San Francisco
              and now live in New York.
            </p>
            <p>
              Before FreeWheel, I started PreVue - a cross-platform prototyping
              tool for developers. I did my
              academic work at New York University.
            </p>
            <p>
              In my free time, I enjoy jogging along the Hudson pier, going to
              concerts, and scouring food delivery websites while convincing
              myself I deserve a break from cooking.
            </p>
          </div>
        </section>
      </>
    );
  }
}

export default About;
