import React from 'react';

import '../../base-styles/base.scss';
import './footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer>
          <p>&copy; Hubert Lin, 2019</p>
        </footer>
      </>
    );
  }
}

export default Footer;
