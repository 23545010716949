import React from 'react';

import '../../base-styles/base.scss';
import './portfolio.scss';

class Portfolio extends React.Component {
  render() {
    return (
      <>
        <section id="work">
          <h3>Open-source Work</h3>
          <section id="tech-content">
            <div>
              <h4>PreVue</h4>
              <img
                src={require('./assets/prevue.png')}
                alt="preview of the PreVue"
              />
            </div>
            <div>
              <br />

              <p>
                PreVue is an open-source cross-platform prototyping tool for Vue
                developers that allows developers to easily prototype their
                application architecture, preventing bugs and unnecessary
                refactors during development.
              </p>

              <br />
              <p>
                The response has been incredible since launch. Trended on
                Github's All and Vue repositories and was on top post on
                Reddit's /r/vuejs in a single day, with now over 1000 stars on
                Github. Starred by core members of the Vue.js team and one of
                the creators of Nuxt.js.
              </p>

              <br />
              <a
                href="https://www.prevue.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                PreVue &rarr;
              </a>
            </div>
          </section>
        </section>
      </>
    );
  }
}

export default Portfolio;
