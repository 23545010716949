import React from 'react';

import '../../base-styles/base.scss';
import './contact.scss';

class Contact extends React.Component {
  render() {
    return (
      <>
        <section id="contact">
          <h2>Contact</h2>

          <ul>
            <li>
              <a
                href="mailto:huberthflin@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require('./assets/email.png')} alt="email icon" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/hubertlin_"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require('./assets/twitter.png')} alt="twitter icon" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/lin-huberth/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require('./assets/linkedin.png')}
                  alt="LinkedIn icon"
                />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/hubelin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require('./assets/github.png')} alt="GitHub icon" />
              </a>
            </li>
          </ul>
        </section>
      </>
    );
  }
}

export default Contact;
