import React from 'react';

import './header.scss';

class Header extends React.Component {
  render() {
    return (
      <>
        <nav>
          <ul>
            <li id="name">
              <a href="/">Hubert Lin</a>
            </li>
          </ul>
          <ul>
            <div id="nav">
              <li>
                <a href="#work">work</a>
              </li>
              <li>
                <a href="#about">about</a>
              </li>
              <li>
                <a href="#contact">contact</a>
              </li>
            </div>
          </ul>
        </nav>
      </>
    );
  }
}

export default Header;
